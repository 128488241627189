<template>
  <v-dialog :max-width="370" v-model="show">
    <template v-slot:activator="{on}">
      <v-btn color="primary" class="ml-2" v-on="on" @click="open">Dodaj</v-btn>
    </template>
    <v-form :valid="valid" @submit.prevent="submitHandler" ref="form" lazy-validation>
      <v-card>
        <v-card-title class="py-6">
          <h6 class="text-h6 mx-auto">Dodawanie kategorii</h6>
        </v-card-title>
        <v-card-text>
          <AllCategoriesAutocomplete v-model="form.categoryId" />
          <v-text-field
            v-model.number="form.transactionIncrement"
            label="Przyrost"
            dense
            outlined
            type="number"
          ></v-text-field>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-5">
          <v-spacer />
          <v-btn elevation="0" @click="close">Anuluj</v-btn>
          <v-btn color="primary" type="submit" :loading="loading">Dodaj</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import AllCategoriesAutocomplete from '@/components/UI/Autocompletes/AllCategoriesAutocomplete'

export default {
  name: 'TradeWatchModal',
  components: {AllCategoriesAutocomplete},
  data() {
    return {
      valid: true,
      show: false,
      form: {
        transactionIncrement: 0,
        categoryId: 0,
      },
      loading: false,
    }
  },
  methods: {
    submitHandler() {
      if (this.$refs.form.validate()) {
        this.$emit('formValidated', this.form)
      }
    },
    close() {
      this.$refs.form.reset()
      this.show = false
    },
    open() {
      this.show = true
    },
  },
}
</script>

<style scoped></style>
