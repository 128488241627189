var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-col',{attrs:{"cols":12}},[_c('v-card',[_c('v-card-title',[_c('WeekNumberPicker',{attrs:{"prepend-inner-icon":"mdi-calendar-range","className":"shrink mr-3","readonly":""},model:{value:(_vm.weekOfYear),callback:function ($$v) {_vm.weekOfYear=$$v},expression:"weekOfYear"}}),_c('TradeWatchModal',{ref:"modal",on:{"formValidated":_vm.addHistory}}),_c('v-spacer'),_c('v-text-field',{staticClass:"shrink",attrs:{"append-icon":"mdi-magnify","label":"Wyszukaj"},on:{"blur":_vm.fetchItems,"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.fetchItems($event)}},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items,"options":_vm.$data.$_paginationMixin_options,"item-key":"id","loading":_vm.$data.$_paginationMixin_fetchingItems,"search":_vm.search,"items-per-page":_vm.$data.$_paginationMixin_itemsPerPage,"page":1,"server-items-length":_vm.$data.$_paginationMixin_totalCount,"footer-props":{
        'items-per-page-options': _vm.$data.$_paginationMixin_rowsPerPageOptions,
      }},on:{"update:options":function($event){return _vm.$set(_vm.$data, "$_paginationMixin_options", $event)}},scopedSlots:_vm._u([{key:"item.category",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(item.category.name)+" ")]}},{key:"item.delete",fn:function(ref){
      var item = ref.item;
return [_c('v-icon',{on:{"click":function($event){return _vm.deleteCategoryHandler(item)}}},[_vm._v("mdi-delete")])]}},{key:"item.transactionIncrement",fn:function(ref){
      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getPercentage(item.transactionIncrement))+" ")]}}])}),_c('Confirm',{ref:"modalConfirm"})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }