<template>
  <v-col :cols="12">
    <v-card>
      <v-card-title>
        <WeekNumberPicker
          prepend-inner-icon="mdi-calendar-range"
          className="shrink mr-3"
          readonly
          v-model="weekOfYear"
        />
        <TradeWatchModal ref="modal" @formValidated="addHistory" />
        <v-spacer />
        <v-text-field
          append-icon="mdi-magnify"
          class="shrink"
          @blur="fetchItems"
          @keyup.enter="fetchItems"
          v-model="search"
          label="Wyszukaj"
        />
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="$data.$_paginationMixin_options"
        item-key="id"
        :loading="$data.$_paginationMixin_fetchingItems"
        :search="search"
        :items-per-page="$data.$_paginationMixin_itemsPerPage"
        :page="1"
        :server-items-length="$data.$_paginationMixin_totalCount"
        :footer-props="{
          'items-per-page-options': $data.$_paginationMixin_rowsPerPageOptions,
        }"
      >
        <template v-slot:item.category="{item}">
          {{ item.category.name }}
        </template>
        <template v-slot:item.delete="{item}">
          <v-icon @click="deleteCategoryHandler(item)">mdi-delete</v-icon>
        </template>
        <template v-slot:item.transactionIncrement="{item}">
          {{ getPercentage(item.transactionIncrement) }}
        </template>
      </v-data-table>
      <Confirm ref="modalConfirm" />
    </v-card>
  </v-col>
</template>

<script>
import paginationMixin from '@/mixins/pagination.mixin'
import WeekNumberPicker from '@/components/UI/WeekNumberPicker'
import RepositoryFactory from '@/repositories/RepositoryFactory'
import Confirm from '@/components/UI/Confirm'

import {mapActions} from 'vuex'
import TradeWatchModal from '@/components/Modals/TradeWatchModal'

const ReportsRepository = RepositoryFactory.get('reports')
const {methods} = ReportsRepository

export default {
  mixins: [paginationMixin],
  components: {
    TradeWatchModal,
    WeekNumberPicker,
    Confirm,
  },
  name: 'TradeWatchTable',
  data() {
    return {
      search: '',
      uploadInProgress: false,
      weekOfYear: 1,
      range: true,
      items: [],
      totalItems: [],
      headers: [
        {text: 'Nazwa kategorii', value: 'category', width: '80%'},
        {text: 'Przyrost', value: 'transactionIncrement', width: '13%'},
        {text: 'Usuń', value: 'delete', width: '7%', sortable: false},
      ],
    }
  },
  computed: {
    searchOptions() {
      return {
        ...this.$data.$_paginationMixin_options,
        weekOfYear: this.weekOfYear,
      }
    },
  },
  watch: {
    searchOptions: {
      handler() {
        this.fetchItems()
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions(['getReportHistory', 'uploadReport', 'showNotification']),
    getPercentage(val) {
      const num = val * 100
      return `${num.toFixed(1)}%`
    },
    async deleteCategoryHandler(item) {
      const {category, id} = item
      const res = await this.$refs.modalConfirm.open({
        title: 'Usuwanie kategorii',
        message: `Czy na pewno chcesz usunąć kategorię "${category.name}"?`,
      })
      if (res) {
        this.$data.$_paginationMixin_fetchingItems = true
        await methods.deleteTradeWatchHistory({tradeWatchHistoryId: id})
        await this.fetchItems()
        this.showNotification({
          type: 'success',
          message: 'NOTY_TRADEWATCH_KATEGORY_REMOVED',
        })
      }
    },
    async fetchItems() {
      const {searchOptions, search} = this
      this.$data.$_paginationMixin_fetchingItems = true
      const {weekOfYear} = searchOptions
      const options = {
        weekOfYear,
      }
      const query = this.$_paginationMixin_createQuery(
        {
          ...this.$data.$_paginationMixin_options,
          search,
        },
        options
      )
      const {data} = await methods.getReportHistory('TRADEWATCH', query)
      const {totalCount, tradeWatchHistories} = data
      this.$data.$_paginationMixin_totalCount = totalCount
      this.items = tradeWatchHistories
      this.$data.$_paginationMixin_fetchingItems = false
    },
    async addHistory(form) {
      const {categoryId, transactionIncrement} = form
      try {
        await methods.addTradeWatchHistory({
          weekOfYear: this.weekOfYear,
          categoryId,
          transactionIncrement: transactionIncrement / 100,
        })
        await this.fetchItems()
        this.$refs.modal.close()
        this.showNotification({
          type: 'success',
          message: 'NOTY_TRADEWATCH_CATEGORY_ADDED',
        })
      } catch ({response}) {
        this.showNotification({
          type: 'error',
          message: 'NOTY_TRADEWATCH_ADD_CATEGORY_ERROR',
        })
      }
    },
  },
}
</script>

<style scoped></style>
