<template>
  <TradeWatchTable />
</template>

<script>
import TradeWatchTable from '@/components/Reports/TradeWatchTable'

export default {
  name: 'TradeWatch',
  components: {
    TradeWatchTable,
  },
}
</script>

<style scoped></style>
