<template>
  <v-select
    class="shrink"
    dense
    outlined
    hide-details
    prepend-inner-icon="mdi-calendar-range"
    :items="items"
    :value="value"
    @input="onInput"
  >
    <template v-slot:item="{item}">
      {{ `${item} tydzień` }}
    </template>
    <template v-slot:selection>
      {{ `${value} tydzień` }}
    </template>
  </v-select>
</template>

<script>
export default {
  name: 'WeekNumberPicker',
  props: {
    value: {
      type: Number,
      required: true,
    },
  },
  methods: {
    onInput(val) {
      this.$emit('input', val)
    },
  },
  computed: {
    items() {
      return new Array(52).fill('').map((_, idx) => idx + 1)
    },
  },
}
</script>

<style scoped></style>
